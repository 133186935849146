/* MAKE SURE TO DO THIS AS NEEDED!!! */

* {
  box-sizing: border-box;
  font-family: "Open Sans";
  background: #121212;
}

body {
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  padding: 5px;
}

.slider {
  margin-top: 50px;
  margin-bottom: 50px;
  position: relative;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  .slider {
    /* transform: scale(0.5); */
    display: none;
  }
}

.image {
  width: 800px;
  height: 600px;
  border-radius: 10px;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 32px;
  font-size: 3rem;
  color: #fff;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 3rem;
  color: #fff;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition: 1s;
  transform: scale(1.08);
}

.agencyPNG {
  background-color: #fff;
  border-radius: 20px;
}

.header-text {
  font-size: 100px;
  font-family: 'Raleway';
  font-weight: 900;
  color: #808080;
}

@media screen and (max-width: 960px) {
  .header-text {
    font-size: 55px;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
    text-decoration: underline;
    hyphens: auto;
  }
}